
import './Pages.scss'


//import { NavLink } from "react-router-dom"



function Home(){
    return(
        <>
        <div className='home-main'>
            <div className='bg-main'>
            <div className='shadow'>
                <div className='hellotext-box'>
                    <span>Hello!!!</span>
                </div>
                <div className='iamtext-box'>
                    <span>I'm Preeti Kaushik</span>
                </div>
                <div className="developer-text">
                    <span>I'm Passionate</span><span className='web'>Web Developer</span>
                </div>

    


            </div>
            </div>
           {/*<div className='home-about-section'>
                <div className='box'>
                    <div className='any-textbox'>
                        <span>Any Type Of Query
                           <div>& Discussion</div> </span>
                    </div>
                    <p>I'm excited about the endless possibilities that lie ahead and look forward to connecting with fellow professionals who share the same passion for coding and creating exceptional web experiences."
</p>
                </div>

                <div className='box'>
                    <div className='btn-box'>
                        <button><NavLink to="/contact">Contact Me</NavLink></button>
                    </div>
                </div>
    </div>*/}
         
        </div>




              </>
    )
}
 





export default Home