
function Footer(){
    return(
        <>
            <div className='footer'>
                <div className='footer-text-box'>
                    <span>© 2023  Made in React , Develope by  <span className='span'>Preeti Kaushik .</span></span>
                </div>

               
            </div>
        </>
    )
}


export default  Footer