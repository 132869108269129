
import { NavLink } from "react-router-dom"
import './Components.scss'
//import preetilogo from '../Assets/Images/signature2.png'

function Header(){
    return(
        <>
        <div className="header">
            <div className="logo">
                <div className="preeti"><NavLink to="/" className={'p-logo'}><span>Preeti Kaushik</span>
</NavLink></div>
            </div>
            <div className="nav">
            <NavLink to="/">Home</NavLink>
            <NavLink to="/about">About</NavLink>
            <NavLink to="/skills">Skills</NavLink>
            <NavLink to="/project">Project</NavLink>
            <NavLink to="/contact">Contact</NavLink>
            </div>
        </div>
       
        </>
    )
}


export default Header