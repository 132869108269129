

function Breadcrumb(props){
    return(
        <>
           <div className="breadcrumb"><span> <a href="{props.url}">Home</a>{props.pagename}</span></div>
          
        </>
    )
}


export default Breadcrumb