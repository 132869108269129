
//import { NavLink } from "react-router-dom"

import workimage from '../Assets/Images/one.jpg'
import { NavLink } from "react-router-dom"

import './Pages.scss'
import Breadcrumb from "../Components/Breadcrumb"

function Portfolio(){
    return(
        <>
         <Breadcrumb pagename=" »  Portfolio" url="https://preetikaushik.com/"/> 
         <div className='portfolio-main'>
        
            <div className='portfolio-inner'>
                  <div className='portfolio-textbox'>
                    <span>PORTFOLIO<span className='dot'>.</span></span>
                    </div> 

                <div className='project-outerbox'>
                    <div className='project-box'>
                        <div className='img-box'>
                            <img src={workimage} alt='work' className='img'/>
                        </div>
                        <div className='project-textbox'>
                           <NavLink to="https://github.com/preetikaushik1/javascript-slick-carousel.git"> <span>View Work <span><i className="fa fa-long-arrow-right" aria-hidden="true"></i>
</span></span></NavLink>
                        </div>
                    </div>


                    <div className='project-box'>
                    <div className='img-box'>
                            <img src={workimage} alt='work' className='img'/>
                        </div>
                        <div className='project-textbox'>
                        <NavLink to="https://github.com/preetikaushik1/bootstrap-work.git"> <span>View Work <span><i className="fa fa-long-arrow-right" aria-hidden="true"></i>
</span></span></NavLink>
                        </div>
                    </div>

                    <div className='project-box'>
                    <div className='img-box'>
                            <img src={workimage} alt='work' className='img'/>
                        </div>
                        <div className='project-textbox'>
                        <NavLink to="https://github.com/preetikaushik1/javascript-digital-clock.git">   <span>View Work <span><i className="fa fa-long-arrow-right" aria-hidden="true"></i>
</span></span></NavLink>
                        </div>
                    </div>

                    <div className='project-box'>
                    <div className='img-box'>
                            <img src={workimage} alt='work' className='img'/>
                        </div>
                        <div className='project-textbox'>
                        <NavLink to="https://github.com/preetikaushik1/product-listing-react.git"><span>View Work <span><i className="fa fa-long-arrow-right" aria-hidden="true"></i>
</span></span></NavLink>
                        </div>
                    </div>

                    <div className='project-box'>
                    <div className='img-box'>
                            <img src={workimage} alt='work' className='img'/>
                        </div>
                     <div className='project-textbox'>
                       <NavLink to="https://github.com/preetikaushik1/javascript-popup.git"><span>View Work <span><i className="fa fa-long-arrow-right" aria-hidden="true">
                        </i></span></span></NavLink>
                        </div> 
                    </div>

                    <div className='project-box'>
                    <div className='img-box'>
                            <img src={workimage} alt='work' className='img'/>
                        </div>
                        <div className='project-textbox'>
                        <NavLink to="https://github.com/preetikaushik1/javascript-countdown-timer.git"><span>View Work <span><i className="fa fa-long-arrow-right" aria-hidden="true">

                        </i></span></span></NavLink>
                        </div>
                    </div>
                    </div>   
             </div>
        
        </div>
       
            

              </>
    )
}


export default  Portfolio