


import './Pages.scss'

import Breadcrumb from '../Components/Breadcrumb'

function Contact(){
    return(
        <>
            
            <Breadcrumb pagename=" »  Contact" url="https://preetikaushik.com/"/>
       
               <div className='contact-main'>
               <div className='contact-inner'>
                <div className='get-text-box'><span>Get In Touch<span className='dot'>.</span></span></div>
            
            <div className='address-box'>
                <div className='address-innerbox'>
                    <div className='box'>
                        <div className='address-iconbox'>
                        <span><i class="fa fa-map-marker" aria-hidden="true"></i></span></div> 
                        <div className='add-text'><span>Address</span></div>
                        <div className='add-text2'>Mahendragarh ,Haryana</div>

                        
                    </div>

                    <div className='box'>
                    <div className='address-iconbox'>
                    <span><i class="fa fa-envelope"  aria-hidden="true"></i></span></div> 
                        <div className='add-text'><span>Email</span></div>
                        <div className='add-text2'>priteepandit98@gmail.com</div>

                    </div>

                    <div className='box'>
                        <div className='address-iconbox'>
                        <span><i class="fa fa-phone" aria-hidden="true"></i></span></div> 
                      

                        <div className='add-text'><span>Phone</span></div>
                        <div className='add-text2'>9588591702</div>

                    </div>
                </div>
            </div>
            
            <div className='map-box'>
            <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d56226.15593091984!2d76.10342197541165!3d28.263930771808553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1686299536554!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            
            
             </div></div>
      
        </>
    )
}


export default  Contact