
import './Pages.scss'

import Breadcrumb from '../Components/Breadcrumb'
import htmlimg from '../Assets/Skillsimage/html.png'
import cssimg from '../Assets/Skillsimage/css.png'
import bootimg from '../Assets/Skillsimage/bootstrap.png'
import sassimg from '../Assets/Skillsimage/sass.png'
import jsimg from '../Assets/Skillsimage/js.jpg'
import gitimg from '../Assets/Skillsimage/git.png'
import jqueryimg from '../Assets/Skillsimage/jquery.png'
import github from '../Assets/Skillsimage/github.png'
import netlifyimg from '../Assets/Skillsimage/netlify.png'




function Skills(){
    return(
        <>
               
               <Breadcrumb pagename=" »  Skills" url="https://preetikaushik.com/"/>
               <div className='skills-main'>
              
            <div className='skills-inner'>
                <div className='skill-textbox'>My Skills<span className='dot'>.</span></div>
                <div className='skill-text'>
                    <span>Strong understanding in HTML, CSS, JavaScript, React and jQuery to make a responsive design using Bootstrap.focus on user experience and accessibility.
Familiar with frontend development tools and techniques.

</span>
                </div>


                <div className='skill-img-outer'>
                    <div className='skill-box'>
                        <img src={htmlimg} alt='HTML' className='img1'/>
                        <div className='html'>HTML</div>
                    </div>






                    <div className='skill-box'>
                    <img src={cssimg} alt='css' className='img1'/>
                    <div className='html'>CSS</div>

                    </div>


                    <div className='skill-box'>
                    <img src={bootimg} alt='HTML' className='img1'/>
                    <div className='html'>BOOTSTRAP</div>

                    </div>

                    <div className='skill-box'>
                    <img src={sassimg} alt='HTML' className='img1'/>
                    <div className='html'>SASS</div>

                    </div>

                    <div className='skill-box'>
                    <img src={jsimg} alt='HTML' className='img1'/>
                    <div className='html'>JavaScript</div>

                    </div>

                    <div className='skill-box'>
                    <img src={gitimg} alt='HTML' className='img1'/>
                    <div className='html'>Git</div>

                    </div>

                    <div className='skill-box'>
                    <img src={jqueryimg} alt='HTML' className='img1'/>
                    <div className='html'>JQUERY</div>

                    </div>

                    <div className='skill-box'>
                    <img src={github} alt='HTML' className='img1'/>
                    <div className='html'>GITHUB</div>

                    </div>

                    <div className='skill-box'>
                    <img src={netlifyimg} alt='HTML' className='img1'/>
                    <div className='html'>NETLIFY</div>

                    </div>

                    

                </div>










             </div>
        </div>
      
        </>
    )
}


export default  Skills