 
import './Pages.scss'

import Breadcrumb from '../Components/Breadcrumb'
import aboutbanner from '../Assets/Images/about-banner.jpg'

 function About(){
    return(
        <>
        <Breadcrumb pagename=" »  About" url="https://preetikaushik.com/"/>
        <div className='about-main'>
        
            <div className='about-inner'>
                <div className='intro-text-box'><span>Introduction</span><span className='dot'>.</span></div>
            <div className='about-banner-box'>
                <div className='about-banner-innerbox'>
                <img src={aboutbanner} className='img' alt='about'/>
                </div>
            </div>




            <div className='about-text-outerbox'>

                <div className='about-leftbox'>
                     <div>About Me<span className='dot'>.</span></div>
                     <p className='ab-text'>Hi, I’m Preeti Kaushik and <span className='am'>I am designer & developer</span> who 
                 dream making the
                world better place by  creatin gcaptivating products. I am also very active for
               international clients.</p>
              <span className='available-text'><span>Available for new projects</span></span>
              <div className='icon-box'></div>
                         
              </div>

                <div className='about-rightbox'>
                <div>Biography<span className='dot'>.</span></div>
                <div>
                <table>
                            <tr>
                                <td>Name</td>
                                <td>: Preeti Kaushik</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td> : Mahendergarh , Haryana</td>
                            </tr>
                            <tr>
                                <td>Study</td>
                                <td>: P.D.U.Sikar , Rajasthan </td>
                            </tr>
                            <tr>
                                <td>Degree</td>
                                <td>: Master</td>
                            </tr>

                            
                            <tr>
                                <td>Email</td>
                                <td>: priteepandit98@gmail.com</td>
                            </tr>
                        </table>

                </div>
                </div>
            </div>
            </div>
        
        </div>
       
        
        </>
    )
}


export default  About